<template>
  <div>
    <div v-if="error" class="q-pa-lg error apollo">
      <q-banner rounded class="bg-red-2">
        <template v-slot:avatar> <q-icon name="error" color="red" /> </template>An error occurred
        while trying to fetch site statuses:
        <span class="q-pl-sm">{{ error.message.replace('GraphQL error:', '').trim() }}</span>
      </q-banner>
    </div>

    <div>
      <q-table
        title="Site Statuses"
        title-class="text-primary"
        :rows="siteStatuses"
        :sort-method="sortWithDate"
        :columns="columns"
        :visible-columns="
          client && client?.status !== 'SELFREMOVAL'
            ? ['site', 'status', 'updatedAt']
            : ['site', 'status', 'updatedAt']
        "
        row-key="name"
        :loading="$apollo.queries.siteStatusesAndSites.loading"
        :pagination="pagination"
        :filter="filter.trimStart()"
        hide-bottom
      >
        <template v-slot:top-right>
          <!-- <template
            v-if="
              client?.status !== 'SELFREMOVAL' &&
              appName === 'officerprivacy' &&
              !$apollo.queries.siteStatusesAndSites.loading
            "
          >
            <q-select
              multiple
              dense
              :display-value="
                siteForDeepScan[0].name +
                `${siteForDeepScan.length - 1 > 0 ? ` +${siteForDeepScan.length - 1} more` : ''}`
              "
              :model-value="siteForDeepScan"
              @update:modelValue="selectSiteForDeepScan"
              input-debounce="0"
              :label="!siteForDeepScan ? 'All sites for deep scan' : 'Sites for deep scan'"
              :options="[
                { id: 'all', name: 'All sites' },
                ...siteStatusesAndSites.sites
                  ?.filter((site) => site.deepScanEnabled)
                  ?.map((site) => ({ id: site.id, name: site.name })),
              ]"
              :disable="deepScanLoading"
              option-label="name"
              behavior="menu"
              class="q-mr-sm"
              style="width: 15rem"
            >
              <template v-if="siteForDeepScan.filter((el) => el.id)?.length" v-slot:append>
                <q-icon
                  name="cancel"
                  @click.stop.prevent="siteForDeepScan = [{ id: null, name: 'All sites' }]"
                  class="cursor-pointer"
                />
              </template>
            </q-select>
            <div>
              <q-btn
              color="primary"
                v-if="siteStatusesAndSites.client.deepScanStatus !== 'IN_PROGRESS'"
                :loading="deepScanLoading"
                class="q-mr-sm"
                @click="deepScan"
              >
                Deep Scan
              </q-btn>
              <div v-if="siteStatusesAndSites.client.deepScanStatus === 'IN_PROGRESS'">
                <q-btn
                  color="secondary"
                  :disable="siteStatusesAndSites.client.deepScanStatus === 'IN_PROGRESS'"
                  class="q-mr-sm"
                  :style="{ width: '335px' }"
                >
                  Deep Scan In Progress For {{ time }}
                </q-btn>
                <q-btn
                  color="red"
                  flat
                  class="q-mr-sm"
                  :loading="cancelDeepScanLoading"
                  @click="cancelDeepScan"
                >
                  Cancel Deep Scan
                </q-btn>
              </div>
            </div>
          </template> -->

          <q-btn
            :loading="quickScanLoading"
            v-if="isUser && appName === 'officerprivacy'"
            class="q-mr-sm"
            flat
            @click="quickScan"
          >
            Quick Scan
          </q-btn>

          <q-toggle
            v-model="manualChecksOnly"
            class="q-px-sm q-pr-md"
            v-if="client && client.status === 'MONITORING'"
            >Only Show Manual Checks</q-toggle
          >

          <q-select
            v-if="appName === 'removemyinfo'"
            class="q-mr-sm"
            :options="[{ id: 'all', name: 'All Sites' }, ...siteCategories]"
            option-value="id"
            option-label="name"
            v-model="filterCategory"
            map-options
            emit-value
          ></q-select>

          <op-input class="q-mr-sm" v-model="filter" placeholder="Search" size="20">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </op-input>

          <q-btn
            @click="$apollo.queries.siteStatusesAndSites.refetch().catch()"
            :loading="$apollo.queries.siteStatusesAndSites.loading"
            icon="refresh"
            flat
            round
          />
        </template>

        <template v-slot:body="props">
          <q-tr class="cursor-pointer" :props="props">
            <router-link
              custom
              v-slot="{ navigate }"
              :to="`/client/${$route.params.clientId}/site/${props.row.siteId}`"
            >
              <q-popup-proxy
                context-menu
                @before-show="
                  () => {
                    siteStatusSelector = props.row.status
                      ? {
                          label: props.row.status,
                          value: props.row.status,
                        }
                      : undefined
                  }
                "
              >
                <div class="row no-wrap">
                  <site-status-selector v-model="siteStatusSelector" />

                  <div class="column justify-center">
                    <q-btn
                      v-close-popup
                      @click="saveSiteStatus(props.row.siteId)"
                      color="grey-7"
                      flat
                      round
                      icon="check"
                    >
                      <q-tooltip>Save</q-tooltip>
                    </q-btn>
                  </div>
                </div>
              </q-popup-proxy>

              <span class="link" @click="navigate" @keypress.enter="navigate" role="link">
                <q-td v-for="(columnData, name) of filterColumnData(props.row)" :key="name">
                  <q-btn
                    v-if="
                      name === 'deepScan' &&
                      columnData &&
                      client &&
                      client?.status !== 'SELFREMOVAL'
                    "
                    :class="{
                      'deep-scan-info': true,
                      active:
                        columnData === 'FOUND' ||
                        columnData === 'FAILED' ||
                        columnData === 'NOTRUN',
                      found: columnData === 'FOUND',
                      'not-found': columnData === 'NOTFOUND',
                      failed: columnData === 'FAILED',
                    }"
                    :label="
                      columnData === 'NOTRUN'
                        ? 'Not Run'
                        : columnData === 'FOUND'
                        ? 'View Result'
                        : columnData === 'NOTFOUND'
                        ? 'NOT FOUND'
                        : columnData === 'FAILED'
                        ? 'View Failed'
                        : columnData
                    "
                    @click.stop="
                      $router.push(
                        `/client/${$route.params.clientId}/deep-scan-results/${props.row.siteId}`,
                      )
                    "
                  />

                  <template v-else>
                    <template v-if="name === 'site'">
                      <div class="row items-center">
                        <q-icon
                          v-if="props.row.instant"
                          name="bolt"
                          color="blue"
                          size="xs"
                          class="q-mr-xs"
                        >
                          <q-tooltip>Instant</q-tooltip>
                        </q-icon>
                        <q-icon
                          v-if="props.row.oneOff"
                          name="star"
                          color="amber"
                          size="xs"
                          class="q-mr-xs"
                        >
                          <q-tooltip>One-off</q-tooltip>
                        </q-icon>
                        {{ columnData }}
                      </div>
                    </template>
                    <template v-else>
                      {{ formatForDisplay(columnData, name) }}
                    </template>
                    <q-avatar
                      v-if="name === 'site' && quickScanColors[props.row.siteId] !== undefined"
                      :color="quickScanColors[props.row.siteId]"
                      size="1em"
                    ></q-avatar>
                  </template>
                </q-td>
              </span>
            </router-link>
          </q-tr>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script src="./SiteStatusesTable.ts"></script>

<style lang="scss">
.deep-scan-info {
  pointer-events: none;
  padding: 2px;
  background-color: gray !important;
  border-radius: 4px;
  color: #ffffff !important;
  text-align: center;

  &.active {
    pointer-events: auto;
  }

  &.found {
    background-color: red !important;
  }

  &.not-found {
    background-color: green !important;
  }

  &.failed {
    background-color: orange !important;
  }
}

.link {
  display: contents;
  background-color: transparent;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: rgba(128, 128, 128, 0.1);
    }
  }
}
</style>
