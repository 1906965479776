import SiteCategoriesEditor from '@/components/SiteCategoriesEditor/SiteCategoriesEditor.vue'
import { siteCategoriesQuery } from '@/queries'
import { cloneDeep } from '@apollo/client/utilities'
import gql from 'graphql-tag'
import { defineComponent } from 'vue'

import { localStoreFilters } from '@/utils'
import type { ApolloError } from '@apollo/client'
import type { createSite } from 'schema-types/createSite'
import type { cseOptionsInput } from 'schema-types/globalTypes'
import type { updateSite } from 'schema-types/updateSite'

const AllSiteData = gql`
  fragment AllSiteData on Site {
    id
    name
    notes
    optOutWithoutSearch
    url
    searchPath
    directSearchPath
    optOutPath
    directOptOutPath
    directAddressSearchPath
    directPhoneSearchPath
    directEmailSearchPath
    categories {
      id
      name
    }
    automationName
    instructionsUrl
    instructionsText
    instructions {
      id
      text
      link
    }
    quickSearchAllParameter
    includeInCseSearch
    cseOptions {
      id
      threshold
      name
      streetAddress
      city
      state
      zipCode
    }
    oneOff
    instant
  }
`
const sitesQuery = gql`
  query sites {
    sites {
      ...AllSiteData
    }
  }
  ${AllSiteData}
`

export default defineComponent({
    name: 'SiteList',
    data() {
        const filters = localStoreFilters.getItem('sites-filter') as
            | {
                sortBy: string
                descending: boolean
                rowsPerPage: number
            }
            | undefined

        return {
            queryError: false as boolean | ApolloError,
            editDialog: false,
            sites: [],
            appName: process.env.VUE_APP_NAME,
            table: {
                columns: [
                    {
                        name: 'edit',
                        required: true,
                        label: 'Edit',
                        align: 'left',
                        sortable: false,
                    },
                    {
                        name: 'name',
                        required: true,
                        label: 'Name',
                        align: 'left',
                        field: 'name',
                        sortable: true,
                    },
                    {
                        name: 'optOutWithoutSearch',
                        required: true,
                        label: 'Direct Opt Out',
                        align: 'left',
                        field: 'optOutWithoutSearch',
                        sortable: true,
                    },
                    {
                        name: 'url',
                        align: 'left',
                        label: 'Url',
                        field: 'url',
                        sortable: true,
                    },
                    {
                        name: 'searchPath',
                        align: 'left',
                        label: 'Generic Search Path',
                        field: 'searchPath',
                        sortable: true,
                    },
                    {
                        name: 'directSearchPath',
                        align: 'left',
                        label: 'Direct Search Path',
                        field: 'directSearchPath',
                        sortable: true,
                    },
                    {
                        name: 'optOutPath',
                        align: 'left',
                        label: 'Generic Opt Out Path',
                        field: 'optOutPath',
                        sortable: true,
                    },
                    {
                        name: 'directOptOutPath',
                        align: 'left',
                        label: 'Direct Opt Out Path',
                        field: 'directOptOutPath',
                        sortable: true,
                    },
                    {
                        name: 'instructionsUrl',
                        align: 'left',
                        label: 'Instructions Url',
                        field: 'instructionsUrl',
                    },
                ],
                pagination: {
                    sortBy: filters?.sortBy || 'name',
                    descending: filters?.descending || false,
                    page: 1,
                    rowsPerPage: filters?.rowsPerPage !== undefined ? filters.rowsPerPage : 50,
                },
            },
            siteData: {
                name: '',
                notes: '',
                url: '',
                searchPath: '',
                directSearchPath: '',
                directAddressSearchPath: '',
                directPhoneSearchPath: '',
                directEmailSearchPath: '',
                optOutPath: '',
                directOptOutPath: '',
                id: '',
                categories: [] as string[],
                automationName: '',
                optOutWithoutSearch: false,
                instructionsUrl: '',
                instructions: [] as Array<{ id?: string; text: string; link?: string }>,
                quickSearchAllParameter: 'ZIPCODE',
                includeInCseSearch: true,
                cseOptions: undefined as undefined | cseOptionsInput,
                oneOff: false,
                instant: false,
            },
            saving: false,
        }
    },

    apollo: {
        sites: {
            query: sitesQuery,
            error(err) {
                this.queryError = err
            },
            result({ data }) {
                if (data) {
                    this.queryError = false
                }
            },
        },
        siteCategories: {
            query: siteCategoriesQuery,
        },
    },

    watch: {
        'table.pagination': {
            handler() {
                this.saveFilters()
            },
        },
    },

    methods: {
        saveFilters() {
            localStoreFilters.setItem('sites-filter', {
                sortBy: this.table.pagination.sortBy,
                descending: this.table.pagination.descending,
                rowsPerPage: this.table.pagination.rowsPerPage,
            })
        },

        filterColumnData(columnData: { [index: string]: string }) {
            const columnOrder = this.table.columns.map(({ field }) => field)

            const exclude = [
                '__index',
                'instructionsText',
                'id',
                '__typename',
                'automationName',
                'notes',
                'categories',
                'instructions',
                'quickSearchAllParameter',
                'includeInCseSearch',
                'cseOptions',
                'directPhoneSearchPath',
                'directAddressSearchPath',
                'directEmailSearchPath',
            ]

            return Object.keys(columnData)
                .filter((key) => !exclude.includes(key))
                .sort((a, b) => columnOrder.indexOf(a) - columnOrder.indexOf(b))
                .reduce((obj: { [index: string]: string }, key) => {
                    obj[key] = columnData[key]
                    return obj
                }, {})
        },

        openBrowser(url: string, path: string) {
            const urlToOpen = /^https?:\/\//.test(path) ? path : url + path
            window.open(urlToOpen, '_blank')
        },

        saveSite() {
            this.saving = true

            if (this.siteData.id) {
                this.$apollo
                    .mutate<updateSite>({
                        mutation: gql`
              mutation updateSite(
                $id: String!
                $name: String
                $notes: String
                $url: String
                $searchPath: String
                $directSearchPath: String
                $directAddressSearchPath: String
                $directPhoneSearchPath: String
                $directEmailSearchPath: String
                $optOutPath: String
                $directOptOutPath: String
                $optOutWithoutSearch: Boolean
                $categories: [String!]
                $automationName: String
                $instructionsUrl: String
                $instructions: [SiteInstructionInput!]
                $quickSearchAllParameter: Site_quickSearchAllParameter
                $includeInCseSearch: Boolean
                $cseOptions: cseOptionsInput
                $oneOff: Boolean
                $instant: Boolean
              ) {
                updateSite(
                  id: $id
                  name: $name
                  notes: $notes
                  url: $url
                  searchPath: $searchPath
                  directSearchPath: $directSearchPath
                  directAddressSearchPath: $directAddressSearchPath
                  directPhoneSearchPath: $directPhoneSearchPath
                  directEmailSearchPath: $directEmailSearchPath
                  optOutPath: $optOutPath
                  directOptOutPath: $directOptOutPath
                  optOutWithoutSearch: $optOutWithoutSearch
                  categories: $categories
                  automationName: $automationName
                  instructionsUrl: $instructionsUrl
                  instructions: $instructions
                  quickSearchAllParameter: $quickSearchAllParameter
                  includeInCseSearch: $includeInCseSearch
                  cseOptions: $cseOptions
                  oneOff: $oneOff
                  instant: $instant
                ) {
                  ...AllSiteData
                }
              }
              ${AllSiteData}
            `,
                        variables: {
                            ...this.siteData,
                            instructions: this.siteData.instructions.map((i) => {
                                const temp = { ...i }
                                delete (temp as any).__typename
                                return temp
                            }),
                            cseOptions: (() => {
                                if (this.siteData.cseOptions === undefined) {
                                    // If custom cse isn't checked, delete any existing value
                                    return null
                                }
                                const temp = { ...this.siteData.cseOptions }
                                delete (temp as any).__typename
                                delete (temp as any).id
                                return temp
                            })(),
                        },
                    })
                    .then(() => {
                        this.saving = false
                    })
                    .catch((err) => {
                        this.saving = false
                        this.$q.dialog({
                            title: 'Error',
                            message: err.message.replace('GraphQL error:', '').trim(),
                        })
                    })
            } else {
                this.$apollo
                    .mutate<createSite>({
                        mutation: gql`
              mutation createSite(
                $name: String!
                $notes: String
                $url: String!
                $searchPath: String!
                $directSearchPath: String
                $optOutPath: String!
                $directOptOutPath: String
                $optOutWithoutSearch: Boolean
                $categories: [String!]
                $automationName: String
                $instructionsUrl: String
                $instructions: [SiteInstructionInput!]
                $quickSearchAllParameter: Site_quickSearchAllParameter
                $includeInCseSearch: Boolean
                $cseOptions: cseOptionsInput
                $oneOff: Boolean
                $instant: Boolean
              ) {
                createSite(
                  name: $name
                  notes: $notes
                  url: $url
                  searchPath: $searchPath
                  directSearchPath: $directSearchPath
                  optOutPath: $optOutPath
                  directOptOutPath: $directOptOutPath
                  optOutWithoutSearch: $optOutWithoutSearch
                  automationName: $automationName
                  categories: $categories
                  instructionsUrl: $instructionsUrl
                  instructions: $instructions
                  quickSearchAllParameter: $quickSearchAllParameter
                  includeInCseSearch: $includeInCseSearch
                  cseOptions: $cseOptions
                  oneOff: $oneOff
                  instant: $instant
                ) {
                  ...AllSiteData
                }
              }
              ${AllSiteData}
            `,
                        variables: {
                            ...this.siteData,
                        },
                        refetchQueries: ['sites'],
                    })
                    .then(() => {
                        this.saving = false
                        this.resetSiteData()
                    })
                    .catch((err) => {
                        this.saving = false
                        this.$q.dialog({
                            title: 'Error',
                            message: err.message.replace('GraphQL error:', '').trim(),
                        })
                    })
            }
        },
        deleteSite() {
            if (!this.siteData.id) {
                return
            }

            this.$q
                .dialog({
                    title: 'Delete site',
                    message: 'Are you sure you want to delete this site? This cannot be undone.',
                    cancel: true,
                    ok: {
                        label: 'Delete',
                        color: 'red',
                    },
                })
                .onOk(async () => {
                    this.$apollo
                        .mutate({
                            mutation: gql`
                mutation deleteSite($id: String!) {
                  deleteSite(id: $id) {
                    id
                  }
                }
              `,
                            variables: {
                                id: this.siteData.id,
                            },
                            refetchQueries: ['sites'],
                        })
                        .then(() => {
                            this.resetSiteData()
                            this.editDialog = false
                        })
                        .catch((err) => {
                            this.$q.dialog({
                                title: 'Error',
                                message: err.message.replace('GraphQL error:', '').trim(),
                            })
                        })
                })
        },
        resetSiteData() {
            this.siteData = {
                name: '',
                notes: '',
                url: '',
                searchPath: '',
                directSearchPath: '',
                directAddressSearchPath: '',
                directPhoneSearchPath: '',
                directEmailSearchPath: '',
                optOutPath: '',
                directOptOutPath: '',
                id: '',
                optOutWithoutSearch: false,
                categories: [],
                automationName: '',
                instructionsUrl: '',
                instructions: [],
                quickSearchAllParameter: 'ZIPCODE',
                includeInCseSearch: true,
                cseOptions: undefined,
                oneOff: false,
                instant: false,
            }
        },
        cloneDeep,
    },

    components: {
        SiteCategoriesEditor,
    },
})
