import { currentUserQuery, instructionDocumentsQuery } from '@/queries'
import { User_authLevel, authLevels } from '@/utils'
import instructionDocuments from '@/views/InstructionDocuments/InstructionDocuments'
import gql from 'graphql-tag'
import { defineComponent } from 'vue'
import { apolloClient } from '../apollo-сlient'

const deepScanStatus = gql`
  query deepScanSearchingStatus($id: String!) {
    deepScanSearchingStatus(id: $id) {
      id
      message
      searchStatus
      deepScanResult
      clientId
    }
  }
`
const checkUserToken = gql`
  query checkUserToken {
    checkUserToken {
      valid
      expiresAt
    }
  }
`
const getGoogleDocQuery = gql`
  query getGoogleDocInstructions($url: String!) {
    getGoogleDoc(url: $url)
  }
`

export default defineComponent({
    name: 'LayoutDefault',

    data() {
        return {
            leftDrawerOpen: this.$q.platform.is.desktop,
            currentUser: {} as { id: string; authLevel: User_authLevel },
            expandInstructions: false,
            toolbarTitle: process.env.VUE_APP_DISPLAYNAME,
            appName: process.env.VUE_APP_NAME,
            transitionName: '',
            timer: null as any,
            timerCheckUser: null as any,
        }
    },

    computed: {
        isClientMoreInfo(): boolean {
            return this.$route.name === 'ClientMoreInfo'
        },
        instructionDocuments() {
            return instructionDocuments
        },
        isOwner(): boolean {
            const currentUser = this.currentUser
            return currentUser && currentUser.authLevel && currentUser.authLevel === 'OWNER'
        },
        isAdmin(): boolean {
            const currentUser = this.currentUser
            return (
                currentUser &&
                currentUser.authLevel &&
                authLevels.indexOf(currentUser.authLevel) >= authLevels.indexOf(User_authLevel.ADMIN)
            )
        },
        isUser(): boolean {
            const currentUser = this.currentUser
            return (
                currentUser &&
                currentUser.authLevel &&
                authLevels.indexOf(currentUser.authLevel) >= authLevels.indexOf(User_authLevel.USER)
            )
        },
    },

    methods: {
        reloadWindow() {
            localStorage.removeItem('user')
            this.$router.push('/')
            window.location.reload()
            apolloClient?.cache?.reset()
        },

        async showInstructionDoc(url: string) {
            // if url contains youtube.com, open in new window
            if (url.includes('youtube.com') || url.includes('youtu.be')) {
                window.open(url, '_blank')
                return
            }

            try {
                const { data } = await this.$apollo.query({
                    query: getGoogleDocQuery,
                    variables: { url }
                })
                // Open new window with the content
                const newWindow = window.open('', '_blank', 'width=800,height=600')
                if (newWindow) {
                    newWindow.document.write(`
                <html>
                    <head>
                        <title>Instructions</title>
                        <style>
                            body { padding: 20px; font-family: Arial, sans-serif; }
                        </style>
                    </head>
                    <body>
                        ${data.getGoogleDoc}
                    </body>
                </html>
            `)
                    newWindow.document.close()
                }
            } catch (error) {
                this.$q.notify({
                    type: 'negative',
                    message: 'Failed to load instruction document'
                })
            }
        }
    },

    apollo: {
        currentUser: {
            query: currentUserQuery,
            result(result) {
                if (result.data.currentUser && result.data.currentUser.authLevel === 'SELFREMOVAL') {
                    this.expandInstructions = true
                }
            },
        },

        instructionDocuments: {
            skip() {
                return !(this as any).currentUser
            },
            query: instructionDocumentsQuery,
        },
    },

    mounted() {
        const user = localStorage.getItem('user')

        if (user && this.$route.name === 'home') {
            this.$router.push('/clients')
        }

        this.timer = setInterval(async () => {
            const deepScanStatusId = localStorage.getItem('deep-scan-status')

            if (deepScanStatusId) {
                await this.$apollo
                    .query({
                        query: deepScanStatus,
                        variables: {
                            id: deepScanStatusId,
                        },
                        fetchPolicy: 'no-cache',
                    })
                    .then(({ data }) => {
                        const reload = () => {
                            if (this.$route.path.includes(`${data.deepScanSearchingStatus.clientId}`)) {
                                window.location.reload()
                            }
                        }

                        if (data.deepScanSearchingStatus.searchStatus === 'FINISHED') {
                            localStorage.removeItem('deep-scan-status')

                            if (data.deepScanSearchingStatus.deepScanResult === 'FOUND') {
                                return this.$q
                                    .dialog({
                                        title: 'Deep scan completed',
                                        message: 'Deep scan completed. View results?',
                                        cancel: true,
                                    })
                                    .onOk(() => {
                                        this.$router.push(`/client/${data.deepScanSearchingStatus.clientId}`)

                                        reload()
                                    })
                                    .onDismiss(reload)
                                    .onCancel(reload)
                            }

                            if (data.deepScanSearchingStatus.deepScanResult === 'NOTFOUND') {
                                return this.$q.dialog({
                                    title: 'Deep scan completed',
                                    message: 'No results found',
                                })
                                    .onOk(reload)
                                    .onDismiss(reload)
                            }

                            if (data.deepScanSearchingStatus.deepScanResult === 'FAILED') {
                                return this.$q
                                    .dialog({
                                        title: 'Deep scan completed',
                                        message: 'The Deep Scan was blocked by a captcha. Please try again.',
                                        cancel: true,
                                    })
                                    .onOk(() => {
                                        this.$router.push(`/client/${data.deepScanSearchingStatus.clientId}`)

                                        reload()
                                    })
                            }
                        }

                        if (data.deepScanSearchingStatus.searchStatus === 'FAILED') {
                            localStorage.removeItem('deep-scan-status')

                            return this.$q
                                .dialog({
                                    title: 'Deep scan error',
                                    message: '...Ops! Something went wrong',
                                })
                                .onDismiss(reload)
                                .onOk(reload)
                        }
                    })
                    .catch((err) => {
                        if (deepScanStatusId) {
                            this.$q.dialog({
                                title: 'Deep scan error',
                                message: err.message.replace('GraphQL error:', '').trim(),
                            })

                            localStorage.removeItem('deep-scan-status')
                        }
                    })
            }
        }, 10001)

        this.timerCheckUser = setInterval(async () => {
            if (this.currentUser?.id?.length) {
                await this.$apollo
                    .query({
                        query: checkUserToken,
                        fetchPolicy: 'no-cache',
                        context: {
                            headers: {
                                OperationName: 'checkUserToken',
                            },
                        },
                    })
                    .then(({ data }) => {
                        const currentTime = new Date()
                        const timeInMinutes = new Date(currentTime.getTime() + 1.2 * 60 * 1000)

                        if (new Date(data.checkUserToken?.expiresAt) < timeInMinutes) {
                            return this.$q
                                .dialog({
                                    title: 'Warning!',
                                    message: 'Your session will end in 1 minutes. Continue session?',
                                    cancel: true,
                                })
                                .onOk(() => {
                                    window.location.reload()
                                })
                        }
                    })
                    .catch((err) => {
                        this.$q.dialog({
                            title: 'Error check user',
                            message: err.message.replace('GraphQL error:', '').trim(),
                        })
                    })
            }
        }, 1000 * 60)
    },

    beforeUnmount() {
        if (this.timer) clearInterval(this.timer)

        if (this.timerCheckUser) clearInterval(this.timerCheckUser)
    },

    created() {
        this.$router.beforeEach((to, from, next) => {
            const toDepth = to.path.split('/').length
            const fromDepth = from.path.split('/').length

            this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'

            next()
        })
    },
})
