<template>
  <div class="bg-gray-100">
    <div v-if="showTutorialDialog">
      <q-card class="bg-grey-4 q-ma-md">
        <q-card-section>
          <div class="text-h5">Welcome to {{ appDisplayName }}</div>
          <div class="text-subtitle2">
            You're on your way to taking your privacy back. First, check out our getting started
            guide or videos by clicking on one of the buttons below and following along. There are a
            variety of additional instructional documents available in the menu. Each site also has
            its own guide, available by clicking the "Start With These Instructions" button in the
            site status view.
          </div>
        </q-card-section>

        <q-card-actions class="q-gutter-sm" align="right">
          <a
            style="text-decoration: none; color: #000000"
            href="https://www.youtube.com/watch?v=ZIJPn0IE6Rg&list=PLzYxc5i1dVHPwQ1ResWFhuGoeJnF5XE4K"
            target="_blank"
          >
            <q-btn flat color="primary">Getting Started Videos</q-btn>
          </a>

          <a
            style="text-decoration: none; color: #000000"
            href="https://docs.google.com/document/d/14lEDtbvy5w7m2i6wn8EP5I3YYPhD8zyKAth95sPlKO8/edit#heading=h.7akuzlrxmhoz"
            target="_blank"
          >
            <q-btn flat color="primary">Getting Started Guide</q-btn>
          </a>

          <q-btn @click="hideTutorialDialog" flat>Dismiss</q-btn>
        </q-card-actions>
      </q-card>
    </div>

    <div v-if="queryError" class="q-pa-lg error apollo">
      <q-banner rounded class="bg-red-2">
        <template v-slot:avatar>
          <q-icon name="error" color="red" />
        </template>

        An error occurred while trying to fetch
        {{ isUser ? 'clients' : 'your family' }}:
        <span class="q-pl-sm">{{ queryError }}</span>
      </q-banner>
    </div>

    <q-dialog v-model="editDialog">
      <q-card class="w-full" style="max-width: 350px">
        <q-card-section>
          <div class="flex flex-row items-center gap-4 text-h6">
            <div>New {{ isUser ? 'Client' : 'Family Member' }}</div>
            <div class="flex-grow flex flex-row-reverse">
              <q-btn color="secondary" flat round icon="close" v-close-popup />
            </div>
          </div>
        </q-card-section>

        <q-card-section class="p-8">
          <client-editor @mutationCompleted="editDialog = false"></client-editor>
        </q-card-section>
      </q-card>
    </q-dialog>

    <div class="row">
      <div class="col">
        <q-table
          class="q-ma-sm"
          :title="tableName"
          title-class="text-primary"
          :rows="clients"
          :columns="table.columns"
          :visible-columns="visibleColumns"
          v-model:pagination="table.pagination"
          row-key="name"
          :loading="$apollo.queries.clients.loading"
          :hide-bottom="!isUser"
          :rows-per-page-options="[50, 100, 500, 1000]"
        >
          <template v-slot:top-right>
            <div class="flex flex-row gap-2 items-center">
              <q-toggle v-if="isUser" v-model="requiredChecksOnly">Required Checks Only</q-toggle>

              <q-btn
                v-if="
                  !userId &&
                  !manualChecksOnly &&
                  !reAddedOnly &&
                  !upcomingScans &&
                  (isUser || (clients && clients.length < 6))
                "
                @click="editDialog = true"
                color="primary"
                icon="person_add"
                :label="isUser ? 'Create New' : 'Add Family Member'"
                outline
                rounded
              />

              <q-input
                v-if="isUser"
                debounce="300"
                dense
                v-model="table.filterInput"
                label="Search"
              >
                <template v-slot:prepend>
                  <q-icon name="search" />
                </template>
              </q-input>

              <q-select
                :options="nextScanWithinOptions"
                label="Within"
                v-if="upcomingScans"
                v-model="nextScanWithin"
                map-options
              ></q-select>

              <q-btn
                @click="$apollo.queries.clients.refetch().catch()"
                :loading="$apollo.queries.clients.loading"
                icon="refresh"
                flat
                round
                v-if="$q.screen.width > 420"
              />

              <q-btn
                v-if="isUser && !userId && !manualChecksOnly && !reAddedOnly && !upcomingScans"
                @click="showFiltering = !showFiltering"
                icon="filter_list"
                flat
                round
              />

              <q-toggle v-if="isAdmin" v-model="selectClientsMode">Select Clients</q-toggle>

              <q-btn
                v-if="selectedClients.length > 0"
                @click="showChangeTypeDialog = true"
                color="primary"
                icon="edit"
                label="Change Client Type"
                outline
                rounded
              />
            </div>
          </template>

          <template v-slot:body="props">
            <q-tr class="cursor-pointer" :props="props">
              <q-td v-if="isAdmin && selectClientsMode">
                <q-checkbox v-model="selectedClients" :val="props.row.id" />
              </q-td>
              <router-link custom v-slot="{ navigate }" :to="`/client/${props.row.id}`">
                <q-popup-proxy
                  context-menu
                  @before-show="
                    () => {
                      assignmentSelection = props.row.assignedToId
                        ? {
                            label: props.row.assignedTo,
                            value: props.row.assignedToId,
                          }
                        : undefined
                      typeSelection = props.row.ClientType
                        ? {
                            label: props.row.ClientType,
                            value: typeSelections.find((t) => t.label === props.row.ClientType)
                              .label,
                          }
                        : undefined
                    }
                  "
                >
                  <q-list>
                    <div v-if="isAdmin" class="row no-wrap">
                        <assignment-selector v-model="assignmentSelection" />

                        <div class="column justify-center">
                          <q-btn
                            v-close-popup
                            @click="saveAssignment(props.row.id)"
                            color="grey-7"
                            flat
                            round
                            icon="check"
                          >
                            <q-tooltip>Save</q-tooltip>
                          </q-btn>
                        </div>
                    </div>

                    <q-item clickable v-close-popup @click="openInNewTab(props.row.id)">
                      <q-item-section avatar>
                        <q-icon name="open_in_new" />
                      </q-item-section>
                      <q-item-section>Open in New Tab</q-item-section>
                    </q-item>
                  </q-list>
                </q-popup-proxy>

                <span
                  style="display: contents"
                  @click="navigate"
                  @keypress.enter="navigate"
                  role="link"
                  class="link"
                >
                  <q-td
                    role="link"
                    v-for="(columnData, name) of filterColumnData(props.row)"
                    :key="name"
                  >
                    <span :style="getColStyles(name, props.row)">
                      {{ formatForDisplay(columnData, name) }}
                    </span>
                  </q-td>
                </span>
              </router-link>
            </q-tr>
          </template>
        </q-table>

        <q-dialog v-model="showChangeTypeDialog">
          <q-card class="w-full" style="max-width: 350px">
            <q-card-section>
              <div class="text-h6">Change Client Type</div>
            </q-card-section>
            <q-card-section>
              <q-select
                v-model="newClientType"
                :options="typeSelections"
                label="Select Client Type"
                map-options
              />
            </q-card-section>
            <q-card-actions align="right">
              <q-btn flat label="Cancel" v-close-popup />
              <q-btn flat label="Change" @click="changeClientType" />
            </q-card-actions>
          </q-card>
        </q-dialog>

        <div class="pagination">
          <q-pagination v-model="table.page" :max="table.totalPages" input />
        </div>
      </div>

      <div class="col col-auto q-pa-sm" v-if="showFiltering">
        <q-card style="position: sticky; top: 60px">
          <q-card-section>
            <div class="row items-center justify-between">
              <div class="text-h5">Filtering</div>
              <q-btn flat round icon="close" @click="showFiltering = false" />
            </div>
          </q-card-section>

          <q-card-section>
            <div class="text-subtitle1">Client Statuses</div>
            <div v-for="clientStatus of clientStatuses" class="row" :key="clientStatus">
              <q-checkbox :val="clientStatus" v-model="showStatuses">{{
                clientStatus.toLowerCase().replace(/^./, (t) => t.toUpperCase())
              }}</q-checkbox>
            </div>
          </q-card-section>
            <q-card-section>
              <div class="text-subtitle1">Client Types</div>
              <div v-for="typeSelection of typeSelections" class="row" :key="typeSelection.value">
                <q-checkbox
                  :val="
                    showTypes.find((el) => el.value === typeSelection.value)
                      ? showTypes.find((el) => el.value === typeSelection.value)
                      : typeSelection
                  "
                  v-model="showTypes"
                >
                  {{ typeSelection.label }}
                </q-checkbox>
              </div>
            </q-card-section>
              <q-card-section v-if="isAdmin">
                <div class="text-subtitle1">Queue</div>
                <q-checkbox v-model="waitingQueue" label="Ready" />
              </q-card-section>
            <q-card-section>
            <div class="text-subtitle1">Stripe Statuses</div>
            <div v-for="stripeStatus in ['ACTIVE', 'TRIALING', 'PASTDUE', 'CANCELED', 'NOTFOUND']" :key="stripeStatus" class="row">
              <q-checkbox :val="stripeStatus" v-model="stripeStatusFilter">
                {{ stripeStatus.toLowerCase().replace(/^./, (t) => t.toUpperCase()) }}
              </q-checkbox>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div>

    <div v-if="!isUser" class="q-pl-sm">
      <a href="https://youtu.be/E2-OW4TUivM" target="_blank" class="text-blue underline">
        Need help getting started? Click here for instructional videos.
      </a>
    </div>
  </div>
</template>

<script src="./ClientList.ts"></script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 1rem 1rem;
}
</style>
